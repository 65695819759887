<template>
  <div class="notification-drop" :class="[localDisplayLang === 'eng' ? 'default' : 'flipped']" id="notify-bell">
    <div id="notification-drop-parent" class="drop-header">
      <h3 class="gray-text">{{ $t("Notifications") }}</h3>
      <p
        v-if="notificationArray && notificationArray.length > 0"
        @click="clearAllNotifications"
        class="gray-text clear-all"
      >
        {{ $t("Clear all") }}
      </p>
    </div>
    <div class="body-container">
      <div v-if="notificationArray && notificationArray.length > 0">
        <div
          class="drop-body"
          v-for="(data, index) in notificationArray"
          :key="index"
          @click="notificationAction(data)"
        >
          <div class="left-side-notify">
            <div v-if="data.imageURL" class="notification-image">
              <img class="image-card" :src="data.imageURL" alt="test" />
            </div>

            <div class="notification-body">
              <h4 class="notify-title" :class="[data.isRead ? 'gray-text' : 'white-text']">{{ data.title }}</h4>
              <p class="notify-desc" :class="[data.isRead ? 'gray-text' : 'white-text']">{{ data.message }}</p>
            </div>
          </div>

          <div class="cta" v-if="data.cta && data.deeplink">
            <!-- <button  class="cta-button">{{ data.cta }}</button> -->
            <div class="cta-button">{{ $t(data.cta) }}</div>
          </div>
        </div>
      </div>

      <div v-if="!notificationArray">
        <div class="no-notifications">
          <h4>{{ $t("No Notifications Found") }}</h4>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { eventBus } from "@/eventBus";

import { mapGetters } from "vuex";

export default {
  data() {
    return {
      notificationArray: [],
      localDisplayLang: null,
      deviceId: null,
    };
  },

  mounted() {
    this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");

    this.deviceId = localStorage.getItem("random");

    this.getNotificationsFromLocalStorage();

    console.log("DEVICE ID -->", this.deviceId);
    eventBus.$on("check-local-storage", (data) => {
      this.localDisplayLang = data;
    });
  },

  computed: {
    ...mapGetters(["subscriberid"]),
  },

  methods: {
    showDetails() {
      eventBus.$emit("show-notification-details", true);
    },

    clearAllNotifications() {
      let notificationFromStorage = JSON.parse(localStorage.getItem("notifications"));

      if (notificationFromStorage) {
        // Check if logged in user
        if (this.subscriberid) {
          delete notificationFromStorage[this.subscriberid];
        }
        // If guest user
        else if (this.deviceId) {
          delete notificationFromStorage[this.deviceId];
        }

        console.log("The remaining notifications", notificationFromStorage);

        localStorage.setItem("notifications", JSON.stringify(notificationFromStorage));
        eventBus.$emit("close-notification-drop", true);
      }

      // localStorage.removeItem("notifications");
    },

    notificationAction(data) {
      console.log("DATA ->", data);

      let notifications = JSON.parse(localStorage.getItem("notifications"));

      const deviceId = localStorage.getItem("random");

      let notificationsArr;

      let index;

      if (this.subscriberid) {
        notificationsArr = notifications[this.subscriberid];
      } else if (deviceId) {
        notificationsArr = notifications[deviceId];
      }

      if (!data.isRead) {
        notificationsArr.forEach((el, i) => {
          if (!el.isRead && el.updatedAt === data.updatedAt) {
            index = i;
            console.log("THIS IS THE CLICKED OBJECT", el, i);
          }
        });

        notificationsArr[index] = { ...data, isRead: true };

        console.log("NEW ARRRR -----", notificationsArr);

        if (this.subscriberid) {
          notifications[this.subscriberid] = notificationsArr;
        } else if (deviceId) {
          notifications[deviceId] = notificationsArr;
        }

        localStorage.setItem("notifications", JSON.stringify(notifications));
      }

      if (data.isDirectDeeplink && data.deeplink) {
        window.open(data.deeplink, "_self");
      }

      // Click redirection code - Watch Now
      else if (data.cta == "WATCH NOW" && data.contentId) {
        this.$router.push(`viewContent/${data.contentId}`);
      }

      // Click redirection code - ViewTicket
      else if (data.cta == "VIEW TICKET") {
        const enitreURL = data.deeplink;
        const domain = window.location.host;
        const justParams = enitreURL.split(domain);
        this.$router.push(justParams[1]);
      } else if (data.isDirectDeeplink && data.deeplink) {
      }

      eventBus.$emit("getNotificationsHeaderCount");
    },

    getNotificationsFromLocalStorage() {
      console.log("CLICK ---", localStorage.getItem("notifications"));

      const localNotifications = JSON.parse(localStorage.getItem("notifications"));

      // let deviceId = localStorage.getItem("random");

      let currentUserNotifications;

      if (localNotifications && Object.keys(localNotifications).length > 0) {
        if (this.subscriberid) {
          console.log("This notfy ->", localNotifications[this.deviceId]);

          if (localNotifications[this.deviceId]) {
            currentUserNotifications = [...localNotifications[this.subscriberid], ...localNotifications[this.deviceId]];
            console.log("CONTROL IS HERE", currentUserNotifications);
          } else {
            currentUserNotifications = localNotifications[this.subscriberid];
          }

          // currentUserNotifications = localNotifications[this.subscriberid];
        } else if (localNotifications[this.deviceId]) {
          currentUserNotifications = localNotifications[this.deviceId];
        }

        if (currentUserNotifications && currentUserNotifications.length > 0) {
          currentUserNotifications.sort((a, b) => {
            return b.recievedDate - a.recievedDate;
          });

          this.notificationArray = currentUserNotifications;
        } else {
          this.notificationArray = null;
        }
      } else {
        this.notificationArray = null;
      }

      console.log("THE ARR ->", this.notificationArray);
    },
  },

  beforeDestroy() {
    eventBus.$off("check-local-storage");
  },
};
</script>

<style lang="scss">
@import "@/sass/_variables.scss";

.left-side-notify {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.notification-drop {
  font-family: $font-regular;
  position: absolute;
  width: 450px;
  max-height: 650px;
  overflow-y: scroll;
  top: 120%;
  z-index: 2;

  &.default {
    right: 14%;
  }

  &.flipped {
    left: 14%;
  }

  &::before {
    content: "";
    position: absolute;
    top: -4%;
    right: 9%;
    width: 0%;
    height: 0%;
    display: block;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 15px solid $clr-dark-gd2;
    z-index: 1;
  }
  .drop-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: $clr-dark-gd2;
  }
  .body-container {
    .drop-body {
      display: flex;
      align-items: center;
      padding: 10px;
      min-height: 70px;
      cursor: pointer;
      justify-content: space-between;

      .cta {
        margin: 0px 10px;
        color: $primary-btn-color;
        font-size: 0.8rem;
        align-items: flex-end;
      }

      background-color: $clr-dark-gd3;
      &:hover {
        background-color: $clr-dark-gd2;
        // .notify-title {
        //   color: $clr-light-gd2;
        // }
      }
      &:last-child {
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
      }
      .notification-image {
        margin: 0 8px;
        .image-card {
          width: 84px;
          height: 60px;
          border-radius: 3px;
        }
      }
      .notification-body {
        padding-left: 0.4rem;
        .notify-title {
          font-size: 1rem;
          padding: 3px 0;
        }
        .notify-desc {
          font-size: 0.8rem;
          font-weight: 500;
          padding: 3px 0;
        }
      }
    }
  }

  .no-notifications {
    text-align: center;
    padding: 20px;
    background-color: $clr-dark-gd3;
    color: $clr-light-gd4;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  .clear-all {
    font-weight: bold;
    font-size: 1rem;
    cursor: pointer;
  }

  .gray-text {
    color: $clr-light-gd4;
  }

  .white-text {
    color: $clr-light-gd2;
  }
}

@media only screen and (max-width: 576px) {
  .notification-drop {
    width: 275px;

    max-height: 300px;
    overflow-y: scroll;

    border-radius: 10px;

    .drop-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 15px;
      background-color: $clr-dark-gd2;
    }

    .body-container {
      .drop-body {
        display: flex;
        align-items: center;
        padding: 10px;

        .cta {
          margin: 0px 10px;
          color: $primary-btn-color;
          font-size: 0.5rem;
          align-items: flex-end;
        }

        background-color: $clr-dark-gd3;
        &:hover {
          background-color: $clr-dark-gd2;
          .notify-title {
            color: $clr-light-gd2;
          }
        }
        &:last-child {
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;
        }
        .notification-image {
          .image-card {
            width: 64px;
            height: 40px;
            border-radius: 3px;
          }
        }
        .notification-body {
          padding-left: 0.4rem;
          .notify-title {
            font-size: 0.6rem;
          }
          .notify-desc {
            font-size: 0.5rem;
            font-weight: 500;
          }
        }
      }
    }
    .clear-all {
      font-weight: bold;
      font-size: 0.9rem;
    }
    .gray-text {
      color: $clr-light-gd4;
    }
  }
}
</style>
